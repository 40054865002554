.TestimonialsWrapper {
  background-image: url("../../../public/assets/images/testimonials-back.png");
  display: flex;
  min-height: 650px;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  text-align: center;
}
.testimonial {
  max-width: 904px;
  margin: 0 auto;
  text-align: center;
  padding-top: 110px;
  font-size: 36px;
}
.testimonial .text {
  font-size: 30px;
  font-weight: bold;
}
.testimonial p {
  color: #000;
  text-align: center;
  font-family: "MARKPRO", sans-serif;
}

.testimonial h4 {
  margin-top: 30px;
  font-size: 20px;
  font-weight: bolder;
}

.testimonial h4 span {
  color: #ff7f00;
}

.designation .name {
  font-weight: bold;
}

@media (min-width: 1200px) {
  .testimonial .text {
    font-size: 36px;
  }
}
@media (min-width: 1600px) {
  .TestimonialsWrapper {
    padding-top: 150px;
    min-height: 706px;
  }
}

@media (max-width: 991px) {
  .testimonial .text {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .testimonial .text {
    font-size: 20px;
  }
  .TestimonialsWrapper {
    min-height: auto;
    padding-top: 125px;
    padding-bottom: 70px;
  }
}
