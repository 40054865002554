.webSlider .item h2 {
  line-height: 68px;
  margin-bottom: 15px;
}
.webSlider .item p {
  margin-bottom: 60px;
}
.webSolutions {
  padding: 90px 0px 70px;
}

@media (min-width: 1600px) {
  .webSolutions {
    padding: 174px 0px 75px;
  }
}
@media (max-width: 991px) {
  .webSolutionImage {
    max-width: 450px;
    margin: 0 auto;
    margin-top: 100px;
  }
}
@media (max-width: 767px) {
  .webSolutions {
    padding: 50px 0px;
  }
  .webSlider .item h2 {
    line-height: 43px;
  }
}
