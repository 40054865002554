.howWrapper {
  background-color: #0b314f;
  padding: 85px 0px 30px;
}
.howTop {
  margin-bottom: 50px;
}
.howTop,
.howTop p {
  color: #fff;
}
.howTop h2 {
  margin-bottom: 5px;
}
.howWrapper .howDiv {
  padding: 0px 25px;
}
.howWrapper .howDiv:nth-child(odd) {
  flex: 0 0 58%;
  max-width: 58%;
}

.howWrapper .howDiv:nth-child(odd) {
  padding-left: 69px;
}
.howWrapper .howDiv:nth-child(odd) .howInner {
  padding-left: 70px;
}
.howWrapper .howDiv:nth-child(5) .howInner{
  overflow: hidden;
}
.howWrapper .howDiv:nth-child(even) {
  flex: 0 0 38%;
  max-width: 38%;
}


.howWrapper .howDiv:nth-child(odd) .howText {
  max-width: 532px;
}
.howWrapper .howDiv:nth-child(even) .howText {
  max-width: 482px;
}
.howWrapper .howDiv:nth-child(even) {
  padding-right: 30px;
}

.how {
  position: relative;
  padding-bottom: 36px;
}
.line {
  position: absolute;
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  opacity: 0;
  transform: translateX(-350px);
  overflow-x: hidden; 
  width: calc(100% - 90px);
}
.line.line3,.line.line7{
  transform: translateX(0);
  transform: translateY(-225px);
}
.line.line4,.line.line5,.line.line6{
  transform: translateX(350px);
}
.line.line8,.line.line9,.line.line10{
  transform: translateX(-350px);
}
.line img {
  max-width: initial;
}
.line1 {
  left: 92px;
  top: 43px;
}
.line2 {
  left: 92px;
  top: 43px;
}
.line3 {
  right: -2px;
  top: 58px;
  display: flex;
  justify-content: flex-end;
  height: calc(100% - 36px);
}
.line4 {
  left: 90px;
  top: 9px;
}
.line5 {
  left: 90px;
  top: 9px;
}
.line6 {
  top: 9px;
  left: -130px;
}
.line7 {
  left: -131px;
  top: 26px;
  height: calc(100% + 30px);
}
.line7 img {
  height: 100%;
}
.line8 {
  left: -130px;
  top: 47px;
}
.line9 {
  left: 90px;
  top: 47px;
}
.line10 {
  left: 90px;
  top: 47px;
}
.how h4 {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.how .icon {
  display: inline-flex;
  margin-bottom: 15px;
  padding-right: 15px;
  align-items: center;
  background-color: #0b314f;
  position: relative;
  z-index: 1;
}
.how .icon span svg {
  max-width: 70px;
}

.how p {
  color: #fff;
}
.designIcon svg {
  max-width: 100px !important;
}



.howWrapper .howDiv:nth-child(odd) .howInner {
  padding-left: 70px;
}

@media (max-width: 991px) {
  .howWrapper .howDiv:nth-child(odd) .howInner {
    padding-left: 0px;
  }
}
@media (min-width: 1440px) {
  .howWrapper .howDiv:nth-child(odd) .howInner {
    padding-left: 131px;
  }
    .howTop h2 {
      margin-bottom: 15px;
    }
}


@media (min-width: 1600px) {
  .howWrapper {
    background-color: #0b314f;
    padding: 102px 0px 50px;
  }
  
  .howTop {
    margin-bottom: 50px;
  }
  .howRow{
    padding-top: 60px;
  }

  .line3 {
    right: 0px;
  }
  .line7 {
    left: -131px;
    top: 28px;
    /* height: auto; */
  }
}

@media (max-width: 1199px) {
  .howWrapper .howDiv:nth-child(odd) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .howWrapper .howDiv:nth-child(odd) {
    padding-left: 55px;
  }
  .howWrapper .howDiv:nth-child(even) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .line6,
  .line8 {
    width: 50px;
    left: -70px;
  }

  .line7 {
    left: -71px;
  }
}

@media (max-width: 767px) {
  .howWrapper {
    padding: 50px 0px 5px;
  }
  .howWrapper .howDiv:nth-child(odd) {
    /* flex: 0 0 100%;
    max-width: 100%; */
    padding-left: 15px;
    padding-right: 15px;
  }

  .howWrapper .howDiv:nth-child(even) {
    /* flex: 0 0 100%;
    max-width: 100%; */
    padding-left: 15px;
    padding-right: 15px;
  }
  .line {
    display: none;
  }
  .how {
    padding-bottom: 30px;
  }
  .how .icon {
    margin-bottom: 10px;
  }
}
