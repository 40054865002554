/* Fonts */

/** Generated by FG **/
@font-face {
  font-family: "MARKPRO";
  src: url("fonts/MARKPRO.eot");
  src: url("fonts/MARKPRO.ttf") format("truetype"),
    url("fonts/MARKPRO.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/** Generated by FG **/
@font-face {
  font-family: "MARKPROBOLD";
  src: url("fonts/MARKPROBOLD/MARKPROBOLD.eot");
  src: url("fonts/MARKPROBOLD/MARKPROBOLD.woff2") format("woff");
  src: url("fonts/MARKPROBOLD/MARKPROBOLD.ttf") format("truetype"),
    url("fonts/MARKPROBOLD/MARKPROBOLD.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/** Generated by FG **/
@font-face {
  font-family: "ProximaNova-Regular";
  src: url("fonts/ProximaNova-Regular.eot");
  src: url("fonts/ProximaNova-Regular.woff") format("woff"),
    url("fonts/ProximaNova-Regular.ttf") format("truetype"),
    url("fonts/ProximaNova-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}
section {
  padding-left: 132px !important;
}
html {
  scroll-behavior: smooth;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: "MARKPRO", sans-serif;
  font-size: 16px;
  position: relative;
  color: #000000;
}
.blogSubheading {
  font-size: 20px;
  font-weight: 700;
  color: #000;
}

.blogLinks {
  color: #0000ee;
  text-decoration: underline;
}
.blogContent {
  color: #ff7f00;
  text-decoration: underline;
}

/* .blogLinks:visited {
  color: #551a8b;
} */
.blogLinks:visited {
  color: #0000ee;
}
.blogLinks:hover {
  color: #0000ee;
}
/* .blogLinks:active {
  color: #ff0000;
} */

.blogInnerHeading {
  font-size: 18px;
  font-weight: 600;
  color: black;
}

img {
  max-width: 100%;
}
a {
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}
button:focus {
  outline: 0px;
}
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

h1 {
  font-family: "MARKPROBOLD";
  font-size: 33px;
  line-height: 48px;
  text-transform: uppercase;
}
h2 {
  font-family: "MARKPROBOLD";
  font-size: 30px;
  line-height: 45px;
  text-transform: uppercase;
}

h3 {
  font-family: "MARKPROBOLD";
  font-size: 32px;
  line-height: 45px;
  text-transform: uppercase;
}
p {
  font-size: 18px;
  font-family: "ProximaNova-Regular";
  color: #85818f;
}
#root {
  overflow: clip;
}
.btn {
  border-radius: 50px;
  padding: 1.2rem 2.5rem 0.8rem;
  font-size: 18px;
  min-width: 261px;
  transition: 0.5s ease-in-out;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-primary {
  background: #ff7f00;
  border-color: #ff7f00;
  color: #fff !important ;
}

.text-orange {
  color: #ff7f00;
}
.btn-white {
  background: #fff;
  border-color: #fff;
  color: #171717;
}
.btn-white:hover {
  color: #171717;
}
.form-group {
  margin-bottom: 1.3rem;
}
.pageTopSection {
  padding: 180px 0px 76px;
  text-align: center;
  background-color: #f8fbff;
  margin-bottom: 80px;
}
.pageTopSection h1 span {
  color: #ff7f00;
}
.pageTopSection p {
  font-size: 18px;
  color: #000;
  max-width: 774px;
  margin: 0 auto;
  font-weight: bold;
}
.contactForm .form-group {
  margin-bottom: 2rem;
  display: flex;
  align-items: flex-end;
}
.contactWrapper .col-md-6 {
  flex: 0 0 100%;
  max-width: 100%;
}

.contactWrapper button[type="submit"] {
  border: 0px;
  border-bottom: 1px solid #ff7f00;
  background: transparent;
  color: #ff7f00 !important;
  font-size: 18px;
  cursor: pointer !important;
  margin-top: 20px;
  transition: none;
  padding: 1px 6px;
  line-height: inherit;
  border-radius: 0px;
  height: auto;
  min-width: auto;
  text-transform: capitalize;
}
.contactWrapper .text-center {
  text-align: left !important;
}
.contactWrapper button[type="submit"]:hover {
  transform: scale(1);
}
.contactForm .form-control {
  padding: 1rem 0.75rem;
  height: auto !important;
}
.contactForm .form-control::placeholder,
.contactForm .form-control {
  color: #85818f;
}

.form-control:focus {
  box-shadow: none;
  border-color: #ff7f00;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: #ff7f00;
  border-color: #ff7f00;
}

.contactForm .btn-primary:not(:disabled):not(.disabled).active,
.contactForm .btn-primary:not(:disabled):not(.disabled):active,
.contactForm .show > .btn-primary.dropdown-toggle {
  background: transparent;
  border-color: #ff7f00;
}
.btn-primary:hover {
  background: #fff;
  color: #ff7f00 !important;
  border-color: #ff7f00;
}
.centerSlider {
  padding: 30px 0px;
  margin-bottom: 30px;
}
.centerSlider .slick-center img {
  opacity: 1;
  -ms-transform: scale(1.08);
  transform: scale(1.09);
}
.centerSlider .slick-slide img {
  /* margin: 20px; */
  transition: 0.2s ease-in-out;
  padding: 4%;
}

/* .btn:hover {
  transform: scale(1.05);
} */

/* Custom style */
.accordion-button.collapsed::after {
  content: "+";
}

.accordion-button::after {
  content: "-";
}
.webSliderWrapper .slick-dots {
  text-align: left;
}
.slick-dots li {
  width: 10px !important;
  height: 10px !important;
  margin: 2px !important;
}
.webSliderWrapper .slick-dots li button {
  cursor: pointer;
}
.slick-dots li button::before {
  font-size: 11px !important;
}
.slick-dots li.slick-active button:before {
  color: #ff7f00 !important;
}

.fullClients .slick-dots {
  text-align: center;
  bottom: -45px;
}
/* .fullClients .slick-slide {
  margin: 10px;
} */

/* How we Do */
.howWrapperDiv .row .col-lg-6:first-child .howDiv,
.howWrapperDiv .row .col-lg-6:nth-child(8) .howDiv ,
.howWrapperDiv .row .col-lg-6:nth-child(4) .howDiv {
  overflow: hidden;
}

.contactForm .form-control {
  border-width: 0px 0px 1px 0px;
  border-radius: 0px;
  padding-left: 3px;
}
.contactForm textarea {
  resize: none;
}
.contactForm {
  padding: 35px 20px 50px;
  background: #fff;
  width: 100%;
}
.form-control:focus {
  background: inherit;
}
.jobContact .contactForm {
  background: transparent;
  padding-left: 0px;
}
.jobContact .contactForm .btn-primary {
  padding: 0.9rem 1rem 0.6rem;
  height: auto;
  min-width: 185px;
}
.jobContact .contactForm .text-center {
  text-align: left !important;
}

.news {
  margin-bottom: 30px;
}
.news .form-check {
  margin-bottom: 10px;
}
/* Tabs Styling */
.nav-tabs {
  width: auto;
  display: inline-flex;
  justify-content: flex-end;
  border: 0px;
}

.nav-tabs a {
  border-color: transparent;
  font-size: 24px;
  color: #333;
  position: relative;
  display: block;
  width: 100%;
  text-align: right;
  border: 0px !important;
  border-radius: 0px;
  background: transparent;
  margin-bottom: 0px;
}
.nav-tabs .nav-link.active {
  color: #ff7f00;
}
.nav-item.active::after {
  content: "";
  position: absolute;
  width: 300px;
  height: 3px;
  background: #ff7f00;
  right: -300px;
  display: inline-block;
  top: 50%;
}

/* Pagination */
.pagination {
  margin: 35px auto 0px;
  display: flex;
  list-style: none;
  justify-content: center;
  outline: none;
  width: 100%;
}
.pagination .previous a {
  font-size: 0px;
  background: url("images/left-arrow.png") no-repeat center center;
}

.pagination .next a {
  font-size: 0px;
  background: url("images/right-arrow.png") no-repeat center center;
}
.pagination .next.disabled a,
.pagination .previous.disabled a {
  opacity: 0.5;
  cursor: auto;
}
.pagination > .active > a {
  background-color: #ff7f00;
  border-color: #ff7f00;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #ccc;
  padding: 5px 10px;
  font-size: 24px;
  outline: none;
  cursor: pointer;
  margin: 0px 10px;
  border-radius: 3px;
  height: 50px;
  width: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #ff7f00;
  border-color: #ff7f00;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #000;
}

/* pagination end */

.contactUsPage {
  padding-top: 180px;
}
.blog-listing-page {
  padding-top: 12em;
  padding-bottom: 75px;
}

.officeSlider .slick-dots {
  position: relative;
  bottom: 0px;
  margin-bottom: 30px;
}
/* accordian */

/* .accordion .card-header:after {
    font-family: 'FontAwesome';  
    content: "\f068";
    float: right; 
}
.accordion .card-header.collapsed:after {/
    content: "\f067"; 
} */

.card-header {
  background-color: transparent;
  border: 0px;
}
.resume label {
  margin: 0px;
  color: #85818f;
  width: 100%;
  padding: 1rem 0.75rem;
  padding-left: 3px;
  border-bottom: 1px solid #ced4da;
}

input[type="file"] {
  display: none;
}

.jobDetailWrapper .name,
.jobDetailWrapper .resume {
  max-width: 100%;
  flex: 0 0 100%;
}

/* .resume input[type="file"]{
  display: none;
}  */

[type="file"] {
  /* Style the color of the message that says 'No file chosen' */
  color: #878787;
}

[type="file"]::-webkit-file-upload-button {
  background: #ff7f00;
  border: 2px solid #ff7f00;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  outline: none;
  padding: 8px 25px 6px;
  text-transform: uppercase;
  transition: all 1s ease;
}

[type="file"]::-webkit-file-upload-button:hover {
  background: #fff;
  border: 2px solid #535353;
  color: #000;
}

@media (min-width: 1200px) {
  h1 {
    font-size: 43px;
    line-height: 57px;
  }

  h2 {
    font-size: 38px;
    line-height: 55px;
  }
}
@media (min-width: 1600px) {
  h1 {
    font-size: 64px;
    line-height: 74px;
  }
  h2 {
    font-size: 60px;
    line-height: 80px;
  }

  h3 {
    font-size: 44px;
    line-height: 55px;
  }
  .btn {
    height: 73px;
  }
  .container {
    max-width: 1470px;
  }
  .contactUsPage {
    padding-top: 245px;
  }
  .blog-listing-page {
    padding-top: 220px;
    min-height: 100vh;
  }
  .pageTopSection h2 {
    font-size: 64px;
  }

  .pageTopSection {
    padding: 245px 0px 102px;
    margin-bottom: 102px;
  }

  .pageTopSection p {
    font-size: 24px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 760px;
  }
  .pagination > li > a {
    width: 42px;
    height: 42px;
  }
  .jobContact .contactForm {
    padding-right: 0px;
  }
  .contactUsPage .contactForm {
    padding-left: 0px;
    padding-top: 0px;
  }
}
@media (max-width: 767px) {
  .blog-listing-page {
    padding-top: 7em;
  }
  .jobContact .contactForm {
    padding-right: 0px;
  }
  h1 {
    font-size: 34px;
    line-height: 45px;
  }
  h2 {
    font-size: 28px !important;
    line-height: 40px !important;
  }
  h3 {
    font-size: 24px !important;
    line-height: 34px !important;
  }
  p {
    font-size: 15px !important;
  }

  .btn {
    padding: 0.8rem 2rem;
    font-size: 16px;
  }
  .contactForm .form-group {
    margin-bottom: 20px;
  }
  .contactForm .form-control {
    padding: 0.375rem 0.2rem;
  }
  .contactUsPage {
    padding-top: 130px;
  }
  .pagination > li > a {
    width: 35px;
    height: 35px;
    margin: 0px 5px;
  }
  .pageTopSection {
    padding: 130px 0px 50px;
    margin-bottom: 50px;
  }
  .centerSlider {
    padding: 10px 0px;
    margin-bottom: 10px;
  }
  .contactUsPage .contactForm {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
  }
}

.slick-active button::before {
  color: grey !important;
  font-size: 15px !important;
}

.slick-dots button::before {
  color: grey !important;
  font-size: 10px !important;
}
