.menu {
  position: absolute;
  top: 0px;
  transition: 0.5s ease-in-out;
  right: -570px;
  height: 100vh;
  width: 570px;
  background: #ff7f00;
  display: flex;
  flex-direction: column;
  color: #fff;
  z-index: 4;
}
.show {
  right: 0px;
}
.hide {
  right: -692px;
}
.menu ul {
  list-style: none;
  padding-top: 30px;
  padding-right: 40px;
}

.menu ul li {
  font-size: 35px;
  color: #fff;
  line-height: 45px;
  padding-bottom: 20px;
}

.menu ul li a {
  color: #fff;
  text-transform: uppercase;
}
.closeMenu {
  cursor: pointer;
  margin-bottom: 60px;
  text-align: right;
}
.conatct {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  margin-top: auto;
  margin-bottom: 45px;
}
.conatct a {
  color: #fff;
  font-size: 30px;
}

.conatct a:first-child {
  font-weight: bold;
}
.closeButton {
  width: 90px;
  height: 90px;
  box-shadow: none;
  border-radius: 10px;
  background: transparent;
  top: 0;
  margin-top: 0;
  margin-left: 0;
  position: absolute;
  display: block;
  z-index: 200;
  text-indent: -9999px;
  border: none;
  right: 0;
  cursor: pointer;
}

.closeButton:before,
.closeButton:after {
  content: "";
  width: 55%;
  height: 6px;
  background: #fff;
  position: absolute;
  top: 48%;
  left: 22%;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.closeButton:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.closeButton:hover:before,
.closeButton:hover:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
@media (min-width: 1600px) {
  .menu {
    right: -692px;
    width: 692px;
  }
  .conatct {
    margin-bottom: 85px;
  }
  .show {
    right: 0px;
  }
  .menu ul {
    padding-top: 50px;
  }
  .menu ul li {
    font-size: 44px;
    padding-bottom: 25px;
    line-height: 55px;
  }
  .closeMenu {
    margin-bottom: 90px;
  }
}
@media (max-width: 767px) {
  .menu {
    width: 100%;
  }
  .menu ul li {
    font-size: 26px;
    padding-bottom: 30px;
    line-height: 30px;
  }
  .conatct a {
    font-size: 20px;
  }
  .closeMenu img {
    max-width: 25px;
  }
  .closeButton:before,
  .closeButton:after {
    width: 39%;
    height: 4px;
  }
}

