.WorkListingInner{
  margin-bottom: 30px;
}
.workItem {
  margin-bottom: 30px;
}
.workItem .image {
  text-align: center;
}
.workDesc {
  padding: 20px 0px;
}

.workDesc h4 {
  font-size: 24px;
  text-transform: uppercase;
}
.workDesc p {
  margin-bottom: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


@media (max-width: 767px) {
.WorkListingInner {
    margin-bottom: 20px;
  }
  .workItem {
    margin-bottom: 15px;
  }
  .workDesc h4 {
    font-size: 21px;
  }
}
