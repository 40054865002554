.clients {
  background: #0b314f;
  color: #fff;
  padding: 95px 0px 70px;
  border-bottom-left-radius: 337px;
  border-top-left-radius: 337px;
  margin-top: 80px;
  margin-bottom: 80px;
}

.clientsList {
  display: flex;
  flex-wrap: wrap;
}

.clientsList .client {
  padding: 0px 12.5px;
  margin-bottom: 25px;
  width: 33.333%;
}
.clients img {
  width: 100%;
  object-fit: cover;
}

@media (min-width: 1600px) {
  .clients {
    padding: 130px 0px 110px;
    margin-top: 90px;
    margin-bottom: 105px;
  }
  .clients h2 {
    font-size: 60px;
    line-height: 80px;
  }
}

@media (max-width: 991px) {
  .clients {
    border-radius: 0px;
  }
  .clients h2 {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .clients {
    padding: 50px 0px 25px;
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .clients h2 {
    font-size: 30px;
  }
  .clientsList .client {
    width: 50%;
  }
}
