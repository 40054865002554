.latestTechnologies {
  padding: 105px 0px;
}
.latestTechnologies h2 {
  text-align: center;
  margin-bottom: 60px;
}
.tech {
  border-radius: 6px;
}

.techRow .techColumn {
  margin-bottom: 30px;
}

.techRow .techColumn:nth-child(3n + 1) .tech:hover .techDesc {
  background-color: #fed1a4;
}
.techRow .techColumn:nth-child(3n + 2) .tech:hover .techDesc {
  background-color: #ddd3ff;
}
.techRow .techColumn:nth-child(3n + 3) .tech:hover .techDesc {
  background-color: #83ffcd;
}
.tech .image {
  text-align: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  max-height: 347px;
  overflow: hidden;
}
.tech .image img {
  height: 264px;
  width: 100%;
  object-fit: cover;
}
.techDesc {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 5px 6px rgb(0 0 0 / 16%);
}
.techDesc h4 {
  font-size: 22px;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.techDesc p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.viewAll {
  margin-top: 50px;
}
.socialIcons {
  display: flex;
  justify-content: flex-start;
  gap: 5%;
  list-style: none;
  padding-left: 0;
}

.shareIcon {
  color: #85818f;
}

.techDesc a:hover {
  color: #fff;
}

.techDesc .readMore {
  text-transform: uppercase;
  padding: 2px;
  color: #1f1f1f;
  border-bottom: 1px solid #1f1f1f;
}
.overlay {
  background-color: rgb(0 0 0 / 70%);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
}
.overlay h1 {
  padding-top: 23%;
  padding-left: 38%;
  color: #fff;
}
@media screen and (min-width: 1600px) {
  .techDesc h4 {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .latestTechnologies {
    padding: 80px 0px 70px;
  }
  .techDesc h4 {
    font-size: 21px;
  }
  .tech {
    margin-bottom: 30px;
  }
  .latestTechnologies h2 {
    margin-bottom: 30px;
  }
  .viewAll {
    margin-top: 30px;
  }
  .techRow .techColumn {
    margin-bottom: 0px;
  }
  .tech .image {
    padding: 30px 15px;
  }
  .tech img {
    max-width: 180px;
    object-fit: cover;
  }
}
