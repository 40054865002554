
.workDetailCover {
  margin-top: 180px;
  margin-bottom: 40px;
}

.objectiveWrapper {
  background: #0b314f;
  color: #fff;
  padding: 80px 0px 60px;
  margin-top: 45px;
}

.cannaRiver h1 {
  font-size: 64px;
}
.cannaRiver {
  margin-bottom: 30px;
}
.cannaRiver h1 {
  margin-bottom: 90px;
}
.riverLeft h3 {
  margin-bottom: 30px;
}
.riverLeft p {
  line-height: 36px;
}

.riverRight ul li {
  margin-bottom: 38px;
}
.riverRight ul li p {
  margin: 0px;
  text-transform: uppercase;
}

.riverRight ul li p:first-child {
  font-size: 18px;
  color: #ff7f00;
}

.riverRight ul li p:last-child {
  font-size: 24px;
  color: #000;
}
.qualityImage {
  background: #f9f9f9;
  padding: 49px 50px;
  height: 100vh;
  overflow-y: scroll;
}

.qualityImage::-webkit-scrollbar {
  width: 0.5vw;
}
.qualityImage::-webkit-scrollbar-thumb {
  background-color: #ddd;
}
.qualityImage::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}
.qualityImage::-webkit-scrollbar-track {
  background-color: #eee;
}

.qualityWrapper {
  margin-bottom: 80px;
}
.qualityWrapper h3 {
  margin-bottom: 30px;
}
.objectiveWrapper p {
  color: #fff;
  line-height: 36px;
}
.objective {
  margin-bottom: 55px;
}
.objective h3 {
  margin-bottom: 15px;
}
.objectiveWrapper {
  margin-bottom: 60px;
}
.bestSelling {
  margin-bottom: 60px;
}
.bestSelling .top p {
  margin-bottom: 50px;
}
.bestSelling img {
  width: 100%;
  object-fit: cover;
}

.innerCracking {
  max-width: 833px;
  margin: 0 auto 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.innerCracking h3 {
  margin-bottom: 0px;
  color: #ff7f00;
  padding: 20px 0px;
  text-transform: capitalize;
}
.verticalLine {
  width: 2px;
  height: 100px;
  display: block;
  background: #ff7f00;
}
.browseWork h2 {
  text-align: center;
  margin-bottom: 40px;
}

@media (min-width: 1600px) {
  .cannaRiver h1 {
    font-size: 84px;
    margin-bottom: 130px;
  }
  .browseWork h2 {
    margin-bottom: 70px;
  }
  .cannaRiver {
    margin-bottom: 120px;
  }
  .qualityImage {
    padding: 49px 100px 78px;
  }
  .cannaRiver {
    margin-bottom: 70px;
  }
  .workDetailCover {
    margin-top: 200px;
  }
  .objectiveWrapper {
    padding: 130px 0px;
  }
}

@media (min-width: 1440px) {
  .objectiveWrapper {
    border-bottom-left-radius: 337px;
    border-top-left-radius: 337px;
  }

}

@media (max-width: 767px) {
.workDetailCover {
    margin-top: 130px;
    margin-bottom: 20px;
  }
  .cannaRiver h1 {
    font-size: 34px;
  }
  .cannaRiver {
    margin-bottom: 40px;
  }
  .cannaRiver h1 {
    margin-bottom: 60px;
  }
  .riverLeft {
    margin-bottom: 30px;
  }
  .riverLeft h3 {
    margin-bottom: 15px;
  }
  .riverRight ul li {
    margin-bottom: 20px;
  }
  .qualityWrapper {
    margin-bottom: 50px;
  }
  .qualityImage {
    padding: 30px;
  }

  .objectiveWrapper {
    padding: 50px 0px 30px;
  }
  .innerCracking {
    margin: 0 auto 60px;
    flex-wrap: wrap;
  }
  .innerCrackingh3 {
    padding-bottom: 10px;
  }
  .innerCracking .verticalLine {
    display: none;
  }
  .bestSelling {
    margin-bottom: 30px;
  }
  .browseWork h2 {
    margin-bottom: 20px;
  }
}
