.ViewWork {
  background: #0b314f;
  color: #fff;
  padding: 90px 0px 70px;
  text-align: center;
}
.ViewWorkInner h2 {
  margin-bottom: 50px;
}

.ViewWorkInner p {
  color: #fff;
  font-size: 36px;
  margin-bottom: 35px;
  text-align: left;
}
.ViewWorkInner .viewWork {
  margin-bottom: 60px;
}

.ViewWorkInner .viewWork .viewWorkbtn {
  background: #ff7f00;
  border-radius: 50px;
  padding: 1.2rem 2.5rem 0.8rem;
  font-size: 18px;
  min-width: 261px;
  transition: 0.5s ease-in-out;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  margin-bottom: 60px;
  cursor: pointer;
}
.ViewWorkInner .viewWork .viewWorkbtn:hover {
  background: #fff;
  color: rgb(0, 0, 0);
}

.ViewWorkInner .getHired {
  text-transform: uppercase;
  padding: 3px 6px;
  border-bottom: 1px solid;
}
.lookingFor {
  max-width: 860px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  /* text-align: left; */
}
.lookingFor ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0px;
}
.lookingFor ul li {
  width: 25%;
  text-align: left;
  margin-bottom: 20px;
}

@media (min-width: 1600px) {
  .ViewWork {
    padding: 150px 0px 100px;
  }
}

@media (max-width: 767px) {
  .ViewWork {
    padding: 50px 0px;
    text-align: center;
  }
}
